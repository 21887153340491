import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import './BalloonText.css';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

function App() {
  const [viewCount, setViewCount] = useState(0);

  const fetchViewCount = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/api/views`, {
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setViewCount(data.views);
    } catch (error) {
      console.error('Error fetching view count:', error);
    }
  }, []);

  useEffect(() => {
    fetchViewCount();
  }, [fetchViewCount]);

  return (
    <div className="App">
      <div className="floating-letters">
        {'Vikas'.split('').map((letter, index) => (
          <span 
            key={index} 
            className="floating-letter balloon-text" 
            data-letter={letter}
            style={{
              animationDelay: `${index * 0.5}s`,
              display: 'inline-block'
            }}
          >
            {letter}
          </span>
        ))}
      </div>
      <div className="view-counter">Views: {viewCount}</div>
    </div>
  );
}

export default App;
